@-webkit-keyframes pulse {
  from {
    opacity: 0.85;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale3d(1.02, 1.02, 1.02);
    transform: scale3d(1.02, 1.02, 1.02);
  }

  to {
    opacity: 0.85;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    opacity: 0.85;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale3d(1.02, 1.02, 1.02);
    transform: scale3d(1.02, 1.02, 1.02);
  }

  to {
    opacity: 0.85;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.canvas {
  position: fixed;
  z-index: -5;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.hiddenInfo {
  position: absolute;
  top: 40px;
  left: 40px;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 13px;
  /* color: rgba(0,0,0,0.4); */

  /* -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;

  -webkit-animation: 1s pulse;
  -o-animation: 1s pulse;
  animation: 1s pulse;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; */

  @media screen and (max-width: 800px) {
    display: none;
  }
}

.button-block {
  position: fixed;
  top: 25px;
  right: 25px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 13px;
  z-index: 5;

  @media screen and (max-width: 800px) {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .button-block,
  .hiddenInfo,
  .canvas {
    display: none;
  }
}

.button-block button {
  box-shadow: none;
  background: transparent;
  font-weight: bold;
  border: none;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 10px;
  background: rgba(255, 255, 255, 0.85);
}

.button-block button:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.75);
}

.button-block button:focus,
.button-block button:active {
  outline: 1px solid rgba(0, 0, 0, 0.1);
}

.clear-icon {
  position: relative;
  width: 10px;
  height: 10px;
  display: block;
  margin-right: 6px;
  margin-bottom: -2px;
}

.clear-icon::before {
  content: '';
  width: 2px;
  height: 10px;
  background-color: black;
  position: absolute;

  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.clear-icon::after {
  content: '';
  width: 2px;
  height: 10px;
  background-color: black;
  position: absolute;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
