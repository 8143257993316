@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  max-width: 550px;
  flex-shrink: 0;

  -webkit-animation: 0.5s fadeIn;
  -o-animation: 0.5s fadeIn;
  animation: 0.5s fadeIn;
}

.inner {
  background: rgba(255,255,255,0.85);
  margin: 24px auto;
}

.cv, .consulting  {
  text-align: left;
}

.cv, .contact, .consulting  {
  padding: 24px;
}

.cv h3,
.consulting h3 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 15px;
  font-family: sans-serif;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding-bottom: 4px;
  /* color: rgba(0,0,0,0.5); */
}

.writing, .code {
  padding: 16px;
}

.downloadLink {
  padding-top: 8px;
  text-align: center;
}

.consulting a,
.contact a,
.downloadLink a {
  color: rgba(0,0,0,0.6);
}

.downloadLink div {
  margin-top: 8px;
}

.consulting a:hover,
.contact a:hover,
.downloadLink a:hover {
  color: rgba(0,0,0,1);
}

.consulting .sec {
  padding-bottom: 15px;
}

.consulting .sec .first {
  margin-top: 0px;
}

.external {
  color: rgba(0,0,0,0.55);
}
