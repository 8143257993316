.image {
  height: 150px;
  width: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0,0,0,0.2);
  display: inline-block;
  margin: 10px;
  position: relative;
  flex-shrink: 0;
  outline: 1px solid rgba(0,0,0,0);

  -webkit-transition: 0.15s all;
  -o-transition: 0.15s all;
  transition: 0.15s all;
}

.image.mobile {
  height: 75px;
  width: 75px;
  margin-left: 0px;
  margin-right: 0px;
  background-color: rgba(255, 255, 255, 1);
}

.image.mobile .badge {
  height: 50px;
  width: 50px;
}

.image.featured,
.image.transparent {
  background-color: rgba(255, 255, 255, 1);
  outline: 1px solid rgba(0,0,0,0.1);
}

.image.active {
  outline: 1px solid rgba(0,0,0,0.1);
}

.image.active:before {
  content: "";
  position: absolute;
  z-index: -1;
  outline: 1px solid rgba(0,0,0,1);
  background: rgba(255,255,255,0.85);
  top: -5px;
  bottom: -5px;
  right: -5px;
  left: -5px;
}

.image h3 {
  z-index: 10;
  background: rgba(255,255,255,0.85);
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 16px;
  font-family: sans-serif;
  font-size: 14px;

  -webkit-transition: 0.15s all;
  -o-transition: 0.15s all;
  transition: 0.15s all;
}

.image:not(.featured):hover {
  cursor: pointer;
  outline: 1px solid rgba(0,0,0,0.7) !important;
}

.image.featured h3 {
  display: none;
}

.image.mobile:hover {
  outline: none !important;
  cursor: default;
}

.image:not(.featured):hover h3,
.image:not(.mobile):hover h3 {
  opacity: 1;
  outline: 1px solid rgba(0,0,0,0.1);
  padding: 17px;
  top: -5px;
  bottom: -5px;
  right: -5px;
  left: -5px;
}

.image.active:hover h3 {
  outline: 1px solid rgba(0,0,0,0.05);
}

.image h3:active, .image h3:focus {
  font-size: 14.5px;
}

.badge {
  height: 65px;
  width: 65px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.description.taller {
  height: 165px;
}

.description.hidden {
  height: 0;
  opacity: 0;
}

.description.invisible {
  opacity: 0;
}

.description {
  height: 135px;
  text-align: left;
  position: relative;
  opacity: 1;
  letter-spacing: 0.15px;
  width: 100%;
  padding-left: 11px;
  padding-right: 11px;

  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

.description .body {
  font-size: 14px;
  line-height: 16px;
  color: rgba(0,0,0,0.75);
}

.featured .description .title {
  margin-top: 7px;
  margin-bottom: 12px;
}

.topRight {
  flex-shrink: 0;
  color: rgba(0,0,0,0.55);
  font-size: 14px;
}

.topRight a, .body a {
  color: rgba(0,0,0,0.6);
}

.topRight a:hover, .body a:hover {
  color: rgba(0,0,0,1);
}

.title {
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.titleLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgba(0,0,0,.85);
}

.title h2 {
  margin: 0px;
  font-size: 20px;
}

.title .arrows {
  font-weight: bold;
  padding-left: 10px;
  margin-left: 0px;
  margin-top: -3px;
  font-size: 14px;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
}

.titleLink:hover {
  color: rgba(0,0,0,1);
}

.titleLink:hover .arrows {
  margin-left: 5px;
}

.section {
  position: relative;
  max-width: 510px;
}

.section.mobile {
  display: none;
  padding-top: 12px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.section.mobile.first {
  padding-top: 0px;
}

.section.mobile.last {
  border-bottom: none;
}

.section.featured {
  display: flex;
  margin-bottom: 15px;
}

.section.featured .description,
.section.mobile .description {
  height: auto;
}

.section.featured .body,
.section.featured .title {
  margin-left: 0;
  margin-right: 0;
}

.section.mobile .title {
  align-items: center;
  justify-content: flex-start;
}

.mobile .title .arrows {
  display: none;
}

.mobile .titleLink {
  margin-right: 16px;
}

.mobile .topRight {
  margin-left: auto;
}

.title h3 {
  margin-top: 0px;
  margin-bottom: -4px;
  font-size: 15px;
  font-family: sans-serif;
  display: flex;
  align-items: center;
}

.section.wrap {
  display: block !important;
}

.section.wrap .image {
  float: left;
  margin-top: 5px;
  margin-right: 20px;
}

.section.wrap .arrows {
  align-self: flex-start;
  margin-top: 0px;
}

@media screen and (max-width: 800px) {
  .section.mobile {
    display: flex;
  }

  .section:not(.mobile) {
    display: none !important;
  }

  .section.mobile .title {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
