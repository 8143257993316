body {
  margin: 0;
  padding: 0;
  /* font-family: 'DM Sans', sans-serif; */

  -webkit-font-smoothing: antialiased;

  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;
}

* {
  box-sizing: border-box;
}
