.portfolio {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

@media screen and (max-width: 800px) {
  .portfolio {
    padding: 0;
  }
}

.header {
  position: relative;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  flex-shrink: 0;

  -webkit-transition: height 0.2s ease-in-out;
  -o-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
}

.header.withRoute {
  height: 125px;
}

.headerInner {
  padding: 24px;
  background: rgba(255,255,255,0.85);
}

.nameLink {
  color: rgba(0,0,0,0.85);
  text-decoration: none;
}

.nameLink:hover {
  color: rgba(0,0,0,1);
}

.nameLink h1 {
  margin-top: 0;
}

.headerLinks {
  color: rgba(0,0,0,0.55);
}

.headerLink {
  padding: 0px 8px;
}

.headerLink a {
  margin: 0px 4px;
  color: rgba(0,0,0,0.6);
  text-decoration: none;
}

.headerLinks a:hover,
.headerLinks a:active {
  color: rgba(0,0,0,0.85);
  text-decoration: underline;
}

.headerLinks a.s-active {
  color: rgba(0,0,0,1);
  /* color: black !important; */
  text-decoration: underline;
}
